import { useState, useRef } from 'react';
import {
  // Card,
  Typography,
  Button,
  Box,
  Collapse,
  List,
  ListItem
} from '@material-ui/core';
import Timeline from '@material-ui/lab/Timeline';
import {
  useTheme,
  experimentalStyled as styled
} from '@material-ui/core/styles';
import { Icon } from '@iconify/react';
import facebookFill from '@iconify/icons-eva/facebook-fill';
import instagramFilled from '@iconify/icons-ant-design/instagram-filled';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import axiosInstance from '../../utils/axios';
import Label from '../Label';

export default function PausedAccountCard(props: any) {
  const theme = useTheme();
  const contentRef = useRef<any>(null);
  const { pausedAccount } = props;
  const [customer, setCustomer] = useState<any>(pausedAccount);
  const [expanded, setExpanded] = useState<boolean>(false);

  const IconStyle = styled(Icon)(({ theme }) => ({
    width: '1.75rem',
    height: '1.75rem',
    flexShrink: 0,

    marginRight: 'auto'
  }));

  const TypoStyle = styled(Typography)(({ theme }) => ({
    width: '1.75rem',
    height: '1.75rem',
    flexShrink: 0

    // marginRight: 'auto'
  }));

  const ButtonBox = styled(Box)(({ theme }) => ({
    minWidth: '6.5rem',
    // marginRight: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1)
  }));

  const PlatformContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: `${theme.spacing(3)} 0 0 0`,
    width: '100%'
  }));

  const ShowMoreLessWrapperStyles = styled(Box)(({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    paddingTop: theme.spacing(2)
  }));

  const ShowMoreLessButtonStyles = styled(Button)(({ theme }) => ({
    color: '#637381'
  }));

  const MainCardStyles = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: theme.spacing(3),
    paddingTop: 0
    // marginBottom: theme.spacing(3)
  }));

  const TimelineStyles = styled(Timeline)(({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin: 0,
    padding: 0,
    '.MuiTimeline-root': {
      padding: '0px 0px'
    }
  }));

  const pauseUnpauseAccount = async (
    paused: any,
    unpaused: any,
    platform: any,
    pk: any
  ) => {
    // If the status is defined then the account is paused
    // Because status will either be Null or a datetime
    const getOpposite = (platform: any) => {
      if (platform == 'Email' && customer.opt_out_email_content) {
        return 'paused';
      }
      if (platform == 'Email' && !customer.opt_out_email_content) {
        return 'unpaused';
      }
      if (platform == 'Blog' && customer.opt_out_blog_content) {
        return 'paused';
      }
      if (platform == 'Blog' && !customer.opt_out_blog_content) {
        return 'unpaused';
      }
      return 'paused';
    };
    const pauseOrUnpause = ['Email', 'Blog'].includes(platform)
      ? getOpposite(platform)
      : !paused && !unpaused
      ? 'paused'
      : paused && !unpaused
      ? 'unpaused'
      : 'paused';

    try {
      const response = await axiosInstance.post(
        'marketing/pause_unpause_social_accounts/',
        {
          status: pauseOrUnpause,
          platform,
          pk
        }
      );
      const { data } = response.data;
      if (data.ok) {
        if (platform === 'facebook') {
          if (pauseOrUnpause === 'paused') {
            setCustomer({
              ...customer,
              unpaused_facebook_account: data.customer_details.unpaused,
              paused_facebook_account: data.customer_details.paused
            });
          } else {
            setCustomer({
              ...customer,
              unpaused_facebook_account: data.customer_details.unpaused,
              paused_facebook_account: data.customer_details.paused
            });
          }
        }

        // Set paused / unpause status for Instagram
        if (platform === 'instagram') {
          if (pauseOrUnpause === 'paused') {
            setCustomer({
              ...customer,
              unpaused_instagram_account: data.customer_details.unpaused,
              paused_instagram_account: data.customer_details.paused
            });
          } else {
            setCustomer({
              ...customer,
              unpaused_instagram_account: data.customer_details.unpaused,
              paused_instagram_account: data.customer_details.paused
            });
          }
        }
        if (platform == 'Email' || platform == 'Blog') {
          setCustomer({
            ...customer,
            opt_out_email_content: data.customer_details.opt_out_email_content,
            opt_out_blog_content: data.customer_details.opt_out_blog_content
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <MainCardStyles>
      <PlatformContainer>
        <IconStyle icon={facebookFill} color="#1877F2" />
        <ButtonBox>
          {!customer.facebook_linked ? (
            <Label color="error">Disconnected</Label>
          ) : (
            ''
          )}
          <Label
            color={
              customer.paused_facebook_account &&
              !customer.unpaused_facebook_account
                ? 'warning'
                : 'success'
            }
          >
            {customer.paused_facebook_account &&
            !customer.unpaused_facebook_account
              ? 'Paused'
              : 'Unpaused'}
          </Label>
        </ButtonBox>
        <ButtonBox>
          <Button
            onClick={() =>
              pauseUnpauseAccount(
                customer.paused_facebook_account,
                customer.unpaused_facebook_account,
                'facebook',
                customer.pk
              )
            }
            variant="outlined"
            color={
              customer.paused_facebook_account &&
              !customer.unpaused_facebook_account
                ? 'success'
                : 'error'
            }
          >
            {customer.paused_facebook_account &&
            !customer.unpaused_facebook_account
              ? 'Unpause'
              : 'Re-Pause'}
          </Button>
        </ButtonBox>
      </PlatformContainer>
      <PlatformContainer>
        <IconStyle icon={instagramFilled} color="#D7336D" />
        <ButtonBox>
          {!customer.insta_linked ? (
            <Label color="error">Disconnected</Label>
          ) : (
            ''
          )}
          <Label
            color={
              customer.paused_instagram_account &&
              !customer.unpaused_instagram_account
                ? 'warning'
                : 'success'
            }
          >
            {customer.paused_instagram_account &&
            !customer.unpaused_instagram_account
              ? 'Paused'
              : 'Unpaused'}
          </Label>
        </ButtonBox>
        <ButtonBox>
          <Button
            onClick={() =>
              pauseUnpauseAccount(
                customer.paused_instagram_account,
                customer.unpaused_instagram_account,
                'instagram',
                customer.pk
              )
            }
            variant="outlined"
            color={
              customer.paused_instagram_account &&
              !customer.unpaused_instagram_account
                ? 'success'
                : 'error'
            }
          >
            {customer.paused_instagram_account &&
            !customer.unpaused_instagram_account
              ? 'Unpause'
              : 'Re-Pause'}
          </Button>
        </ButtonBox>
      </PlatformContainer>
      <PlatformContainer>
        <TypoStyle variant="body2">Email</TypoStyle>
        <ButtonBox>
          <Label color={customer.opt_out_email_content ? 'warning' : 'success'}>
            {customer.opt_out_email_content ? 'Paused' : 'Unpaused'}
          </Label>
        </ButtonBox>
        <ButtonBox>
          <Button
            onClick={() =>
              pauseUnpauseAccount(null, null, 'Email', customer.pk)
            }
            variant="outlined"
            color={customer.opt_out_email_content ? 'success' : 'error'}
          >
            {customer.opt_out_email_content ? 'Unpause' : 'Re-Pause'}
          </Button>
        </ButtonBox>
      </PlatformContainer>
      <PlatformContainer>
        <TypoStyle variant="body2">Blog</TypoStyle>
        <ButtonBox>
          <Label color={customer.opt_out_blog_content ? 'warning' : 'success'}>
            {customer.opt_out_blog_content ? 'Paused' : 'Unpaused'}
          </Label>
        </ButtonBox>
        <ButtonBox>
          <Button
            onClick={() => pauseUnpauseAccount(null, null, 'Blog', customer.pk)}
            variant="outlined"
            color={customer.opt_out_blog_content ? 'success' : 'error'}
          >
            {customer.opt_out_blog_content ? 'Unpause' : 'Re-Pause'}
          </Button>
        </ButtonBox>
      </PlatformContainer>
      <>
        <Collapse collapsedSize={0} in={expanded} timeout={1000}>
          <TimelineStyles style={{ paddingLeft: '0px', marginLeft: '0px' }}>
            <List
              sx={{
                listStyleType: 'disc',
                pl: 2,
                padding: '0px!important',
                '& .MuiListItem-root': {
                  display: 'list-item'
                }
              }}
            >
              {customer && customer.errors
                ? customer.errors.map((err: any, idx: any) => (
                    <ListItem
                      key={idx}
                      sx={{ margin: `${theme.spacing(1)} ${theme.spacing(3)}` }}
                    >
                      {err}
                    </ListItem>
                  ))
                : ''}
            </List>
          </TimelineStyles>
        </Collapse>
      </>
      <ShowMoreLessWrapperStyles>
        <ShowMoreLessButtonStyles
          onClick={() => setExpanded(!expanded)}
          endIcon={expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        >
          <Typography variant="body2" color="#637381">
            {expanded ? 'Show Less ' : 'Show More '}
          </Typography>
        </ShowMoreLessButtonStyles>
      </ShowMoreLessWrapperStyles>
    </MainCardStyles>
  );
}
