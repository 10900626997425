import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import { useState, useEffect, useRef } from 'react';
import {
  Box,
  Card,
  Typography,
  Avatar,
  useMediaQuery,
  useTheme,
  FormControlLabel,
  Switch,
  Button,
  Dialog,
  DialogContentText
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import closeFill from '@iconify/icons-eva/close-fill';
import { Icon } from '@iconify/react';
import { useSnackbar } from 'notistack';
import Scrollbar from '../../../components/ScrollbarHorizontal';
import SubNav from '../../../components/SubNavStaff';
import useAuth from '../../../hooks/useAuth';
import axiosInstance from '../../../utils/axios';
import { MIconButton } from '../../../components/@material-extend';
import PausedAccountCard from '../../../components/profile/PausedAccountCard';
// import FinalizeOnboarding from '../../../components/profile/FinalizeOnboarding';
// ----------------------------------------------------------------------

const ProfileCoverCard = styled(Card)(({ theme }) => ({
  borderRadius: '1rem 1rem 0 0',
  marginTop: theme.spacing(2),
  width: `${
    useMediaQuery(theme.breakpoints.down('md'))
      ? `${theme.breakpoints.values.md}px`
      : '100%'
  }`,
  '&:before': {
    top: 0,
    zIndex: 9,
    width: '100%',
    content: "''",
    minHeight: '5.625rem',
    position: 'absolute',
    backdropFilter: 'blur(0.1875rem)',
    WebkitBackdropFilter: 'blur(0.1875rem)', // Fix on Mobile
    backgroundColor: alpha(theme.palette.primary.darker, 0.72)
  }
}));

const InfoStyle = styled(Box)(({ theme }) => ({
  left: 0,
  zIndex: 99,
  marginTop: 'auto',
  marginBottom: 'auto',
  right: 'auto',
  position: 'relative',
  minHeight: '5.625rem',
  display: 'grid',
  gridTemplateColumns: '1fr',
  alignItems: 'center',
  color: 'white'
}));
const CoverImgStyle = styled('img')(({ theme }) => ({
  top: '0px',
  zIndex: 8,
  width: '100%',
  height: 'var(--avatarSize)',
  objectFit: 'cover',
  position: 'absolute'
}));
const AMCTitleWrapper = styled(Box)(({ theme }) => ({
  display: 'flex'
}));
const DetailsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginLeft: '1rem'
}));
const DetailsItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginRight: '4.5rem',
  minWidth: 'max-content',
  justifyContent: 'space-around',
  height: '4.0625rem'
}));
// ----------------------------------------------------------------------
type AMCTitleProps = {
  links: any;
  needScroll: boolean;
};

export default function EmployeeCustomerNav({
  links,
  needScroll
}: AMCTitleProps) {
  const [modalOpen, setModalOpen] = useState(false);
  const firstRender = useRef(true);
  const { displayedUserDetails } = useAuth();
  const theme = useTheme();
  const subNavWidth = useMediaQuery(theme.breakpoints.down('md'))
    ? `${theme.breakpoints.values.md}px`
    : '100%';
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [readyForCampaigns, setReadyForCampaigns] = useState<boolean>(
    displayedUserDetails?.ready_for_campaigns
      ? displayedUserDetails?.ready_for_campaigns
      : false
  );
  const [onboardingComplete, setOnboardingComplete] = useState<boolean>(
    displayedUserDetails?.onboarding_complete
      ? displayedUserDetails?.onboarding_complete
      : false
  );

  // TODO: Figure out why displayedUserDetails has multiple different shapes
  const avatar =
    displayedUserDetails?.avatar || displayedUserDetails?.avatars?.[0] || '';

  useEffect(() => {
    if (displayedUserDetails && firstRender.current) {
      firstRender.current = false;
      setReadyForCampaigns(
        displayedUserDetails?.ready_for_campaigns
          ? displayedUserDetails?.ready_for_campaigns
          : false
      );
      setOnboardingComplete(
        displayedUserDetails?.onboarding_complete
          ? displayedUserDetails?.onboarding_complete
          : false
      );
    }
  }, [displayedUserDetails]);

  const pausedAccountsModal = (
    <Dialog
      open={modalOpen}
      maxWidth="xs"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogContentText
        id="scroll-dialog-description"
        tabIndex={-1}
        sx={{ padding: 1, marginBottom: 0, height: '100%' }}
      >
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: theme.spacing(3)
          }}
        >
          <Button
            startIcon={<ArrowBackIcon />}
            size="large"
            onClick={() => setModalOpen(!modalOpen)}
          >
            <Typography variant="h5" sx={{ paddingBottom: theme.spacing(0.5) }}>
              Back
            </Typography>
          </Button>
          <Typography variant="h5" sx={{ color: '#212b36' }}>
            Accounts Paused
          </Typography>
        </Box>
        <PausedAccountCard pausedAccount={displayedUserDetails} />
      </DialogContentText>
    </Dialog>
  );

  const changeReadyForCampaigns = () => {
    const newReadyForCampaigns = !readyForCampaigns;
    const makeAPICall = async () => {
      try {
        const res = await axiosInstance.get(
          `set-ready-for-campaigns/${
            newReadyForCampaigns ? 'ready' : 'not_ready'
          }/`
        );
        if (res.data.message == 'success') {
          setReadyForCampaigns(() => newReadyForCampaigns);
          enqueueSnackbar('Customer Updated!', {
            variant: 'success',
            action: (key) => (
              <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill} />
              </MIconButton>
            )
          });
        } else {
          enqueueSnackbar('Something Went Wrong', {
            variant: 'error',
            action: (key) => (
              <MIconButton size="small" onClick={() => closeSnackbar(key)} />
            )
          });
        }
      } catch (err) {
        console.log(
          'the error with the is ready for campaigns API call is ',
          err
        );
        enqueueSnackbar('Something Went Wrong', {
          variant: 'error',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)} />
          )
        });
      }
    };

    makeAPICall();
  };

  const changeOnboardingComplete = () => {
    const makeAPICall = async () => {
      try {
        const res = await axiosInstance.get(
          `onboard-client/${!onboardingComplete ? 'true' : 'false'}/`
        );
        if (res.data.message == 'Success') {
          setOnboardingComplete((prev: boolean) => !prev);
          enqueueSnackbar('Customer Updated!', {
            variant: 'success',
            action: (key) => (
              <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill} />
              </MIconButton>
            )
          });
        } else {
          enqueueSnackbar('Something Went Wrong', {
            variant: 'error',
            action: (key) => (
              <MIconButton size="small" onClick={() => closeSnackbar(key)} />
            )
          });
        }
      } catch (err) {
        console.log(
          'the error with the is ready for campaigns API call is ',
          err
        );
        enqueueSnackbar('Something Went Wrong', {
          variant: 'error',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)} />
          )
        });
      }
    };
    makeAPICall();
  };

  const main = (
    <ProfileCoverCard>
      <InfoStyle>
        <AMCTitleWrapper>
          <Box
            component={Avatar}
            alt="Customer Avatar"
            src={avatar}
            sx={{
              width: '3rem',
              height: '3rem',
              objectFit: 'cover',
              marginRight: '.5rem',
              backgroundColor: 'white',
              borderRadius: '50%',
              marginLeft: '1rem',
              alignSelf: 'center'
            }}
          />
          <DetailsWrapper>
            <DetailsItem>
              <Typography variant="subtitle1">
                {displayedUserDetails?.first_name}{' '}
                {displayedUserDetails?.last_name}
              </Typography>
              <Typography variant="body1">
                {displayedUserDetails?.email}
              </Typography>
            </DetailsItem>
            <DetailsItem>
              <Typography variant="subtitle1">Onboarding</Typography>
              <Typography variant="body1">
                {displayedUserDetails?.onboarding_complete
                  ? 'Completed'
                  : 'Not Onboarded'}
              </Typography>
            </DetailsItem>
            <DetailsItem>
              <Typography variant="subtitle1">Member Since</Typography>
              <Typography variant="body1">
                {displayedUserDetails?.join_date
                  ? displayedUserDetails.join_date
                  : 'NA'}
              </Typography>
            </DetailsItem>

            {displayedUserDetails?.subscription_type === 'AMC' ? (
              <>
                <DetailsItem sx={{ marginTop: theme.spacing(1) }}>
                  <Typography variant="subtitle1">
                    Finalize Onboarding?
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Switch
                          checked={onboardingComplete}
                          onChange={() => {
                            changeOnboardingComplete();
                          }}
                        />
                      }
                      label=""
                      labelPlacement="start"
                    />
                  </Typography>
                </DetailsItem>
                <DetailsItem sx={{ marginTop: theme.spacing(1) }}>
                  <Typography variant="subtitle1">
                    Ready for Campaigns?
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Switch
                          checked={readyForCampaigns}
                          onChange={() => {
                            changeReadyForCampaigns();
                          }}
                        />
                      }
                      label=""
                      labelPlacement="start"
                    />
                  </Typography>
                </DetailsItem>
              </>
            ) : (
              ''
            )}

            <DetailsItem>
              <Typography variant="subtitle1">Paused Acounts</Typography>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => setModalOpen(!modalOpen)}
              >
                Show Accounts
              </Button>
            </DetailsItem>
          </DetailsWrapper>
        </AMCTitleWrapper>
      </InfoStyle>
      <CoverImgStyle
        alt="AMC"
        src="/static/mock-images/contacts/AMCTitleCard.svg"
      />
    </ProfileCoverCard>
  );
  return needScroll ? (
    <Scrollbar>
      {main}
      <Box sx={{ width: subNavWidth }}>
        <SubNav links={links} primaryNav={true} />
      </Box>
      {modalOpen && pausedAccountsModal}
    </Scrollbar>
  ) : (
    <>
      {main}
      <SubNav links={links} primaryNav={true} />
      {modalOpen && pausedAccountsModal}
    </>
  );
}
